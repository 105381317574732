<template>
  <div class="cashin">
    <p class="path">Frequently Asked Questions > TayoCash Services > Cash In</p>
    <h3>Cash In</h3>
    <h4 id="c_i1">How do I put money in my TayoCash wallet?</h4>
    <div class="paragraph">
      <p>You can Cash In or load your TayoCash Wallet through:</p>
      <ol>
        <li>Our <span class="vtxt-align">TayoCash Partners and Merchants</span>; or</li>
        <li><span class="vtxt-align">UnionBank</span> - either by Over-The-Counter Deposit in any UnionBank branch or through the official UnionBank mobile application or online banking portal.</li>
      </ol>
      <p>Cash In transactions are subject to applicable <a class="link" href="/faq/feesandcharges">service fees.</a></p>
    </div>

    <h4 id="c_i2">How do I Cash In through a TayoCash Partner or Merchant?</h4>
    <div class="paragraph">
      <ol>
        <li>In the home page of your app, click the <span class="vtxt-align">Cash In</span> icon and enter the Cash In amount.</li>

        <li>Tap <span class="vtxt-align">Select a Partner, Bank or Merchant</span> to see the list of Partners or Merchants.<!-- You may also tap <span class="vtxt-align">Send now via Partner</span> to see all nearby Partners. --></li>

        <!-- <li>Select a Partner/Merchant and wait for the Partner/Merchant to acknowledge your Cash In request.</li> -->

        <li>Go to the Partner/Merchant and pay the Cash In amount. The Cash In amount, less the applicable service fee, will then be reflected in your TayoCash Wallet.</li>
      </ol>
    </div>

    <h4 id="c_i3">How can I make a Cash In through Over-The-Counter (OTC) bank deposit via UnionBank?</h4>
    <div class="paragraph">
      <ol>
        <li>In the home page of your app, click the <span class="vtxt-align">Cash In</span> icon and enter the Cash In amount.</li>

        <li>Tap the <span class="vtxt-align">Select a Partner, Bank or Merchant</span> tab.</li>

        <li>Tap UnionBank from the <span class="vtxt-align">VIA BANK DEPOSIT</span> option.</li>

        <li>Review the transaction details and take note of the <span class="vtxt-align">TayoCash Transaction Reference Number</span>. Tap <span class="vtxt-align">Continue</span>.</li>

        <li>Go to the nearest <a class="link" href="https://www.unionbankph.com/about-us/news/page/1413" target="_blank">UnionBank Branch</a>.</li>

        <li>At the UnionBank branch, use UnionBank’s tablet, and tap <span class="vtxt-align">Start Here</span> and select <span class="vtxt-align">Bills Payment</span>.</li>

        <li>Search for TayoCash under the list of UnionBank billers. Select TayoCash as the biller.</li>

        <li>Enter the <span class="vtxt-align">TayoCash Transaction Reference Number</span> shown in your TayoCash app.</li>

        <li>Enter your name, email address, and mobile number registered in your TayoCash account. Click <span class="vtxt-align">OK</span>.</li>

        <li>Review the details of your transaction.</li>

        <li>Choose a payment method.</li>

        <li>Wait for your name to be called by the bank teller and proceed with the payment of the Cash In amount and the bank service charge.</li>

        <li>Once you’re done, take a photo of the SMS/email confirmation from UnionBank (showing the <span class="vtxt-align">UnionBank Transaction Reference Number</span>) and upload it through the pop-up notification or the <span class="vtxt-align">Mail</span> icon in your TayoCash app to complete your Cash In request.</li>

        <li>Our Customer Care Representative will validate your Cash In transaction within <span class="vtxt-align">24 hours</span> and once done, the amount you deposited, less any applicable service fee, will be reflected in your TayoCash Wallet.</li>
      </ol>
    </div>

    <h4 id="c_i4">How do I Cash In through the Pay Bills feature in my UnionBank app or online banking portal?</h4>
    <div class="paragraph">
      <ol>
        <li>In the home page of your TayoCash app, click the <span class="vtxt-align">Cash In</span> icon and enter the Cash In amount.</li>

        <li>Tap the <span class="vtxt-align">Select a Partner, Bank or Merchant</span> tab.</li>

        <li>Tap UnionBank from the <span class="vtxt-align">VIA BANK DEPOSIT</span> option.</li>

        <li>Review the transaction details and take note of the <span class="vtxt-align">TayoCash Transaction Reference Number</span>. Tap <span class="vtxt-align">Continue</span>.</li>

        <li>Open your UnionBank mobile app or online banking portal and select “Pay Bills”.</li>

        <li>Search for TayoCash under the list of UnionBank billers. Select TayoCash as the biller.</li>

        <li>Enter the <span class="vtxt-align">TayoCash Transaction Reference Number</span> shown in your TayoCash app and the <span class="vtxt-align">Mobile Number</span> registered in your TayoCash account.</li>

        <li>Enter the amount you want to Cash In.</li>

        <li>Review the summary of your transaction and confirm.</li>

        <li>Take a screenshot or photo of the <span class="vtxt-align">Cash In Transaction Receipt</span> (showing the <span class="vtxt-align">UnionBank Transaction Reference Number</span>) in your UnionBank app or online banking portal and upload it through the in-app notification or the <span class="vtxt-align">Mail</span> button in your TayoCash app to complete your Cash In request.</li>

        <li>Our Customer Care Representative will validate your Cash In transaction within <span class="vtxt-align">24 hours</span> and once done, the Cash In amount, less any applicable service fee, will be reflected in your TayoCash wallet.</li>
      </ol>
    </div>

    <h4 id="c_i5">How do I Cash In from my UnionBank account linked to my TayoCash account?</h4>
    <div class="paragraph">
      <ol>
        <li>In the home page of your app, click the <span class="vtxt-align">Cash In</span> icon and enter the Cash In amount.</li>

        <li>Tap the <span class="vtxt-align">Select a Partner, Bank or Merchant</span> tab.</li>

        <li>Choose UnionBank from the list of online banks.</li>

        <li>The UnionBank authentication page will appear on your app screen.</li>

        <li>Log in your UnionBank account details.</li>

        <li>After log in, select the UnionBank account that you want to Cash In from.</li>

        <li>Confirm your Cash In request and enter the One-Time PIN (OTP) sent to your mobile number.</li>

        <li>The Cash In amount will be deducted from your UnionBank account and will be reflected in your TayoCash Wallet, less any applicable service fee.</li>
      </ol>
    </div>

    <h4 id="c_i6">I do not see the Cash In amount in my wallet, what should I do?</h4>
    <div class="paragraph">
      <ol>
        <li><span class="vtxt-align">For Cash In via a TayoCash Partner or Merchant:</span>
          <ul>
            <li>Please make sure that all the details of the transaction are correct.</li>

            <li>Confirm with the Partner or Merchant if the transaction was successful from their end and request for a transaction receipt if it is successful.</li>

            <li>If the transaction was unsuccessful, ask the Partner or Merchant to try again.</li>
          </ul>
        </li>
        <li><span class="vtxt-align">For Cash In via OTC bank deposit at any UnionBank branch or via the Pay Bills feature through the UnionBank app or online banking portal:</span>
          <ul>
            <li>Kindly upload in your TayoCash app a copy of the Cash In transaction receipt or the SMS/email confirmation you received from UnionBank (showing the UnionBank Transaction Reference Number).</li>

            <li>Please take note that transactions will be confirmed and credited to your TayoCash available wallet balance within 24 hours after uploading a copy of your Cash In transaction receipt or SMS/email confirmation in the TayoCash app. All Cash In requests submitted for validation after 4:00 PM shall begin processing the next business day.</li>
          </ul>
        </li>
        <li>
          <span class="vtxt-align">For Cash In via your UnionBank account linked to your TayoCash account:</span>
          <ul>
            <li>Please make sure that all the details of the transaction are correct.</li>

            <li>Confirm if your bank account was deducted with the Cash In amount.</li>
          </ul>
        </li>
      </ol>
    </div>

    <h4 id="c_i7">I’m still experiencing the same problem or I have other concerns in using the Cash In Service. What do I do?</h4>
    <div class="paragraph">
      <ol>
        <li>
          <router-link class="link" :to="{ path: '/helpcenter', hash: '#submitticket' }">Submit a ticket</router-link> via the TayoCash <a class="link" href="/helpcenter">Help Center</a> in the app or website:
          <ul class="loweralpha">
            <li>Select <span class="vtxt-align">Cash In Transaction</span></li>
            <li>Input the following details in the text field:
              <ul>
                <li>Cash In Channel  (e.g. UnionBank, TayoCash Partner/Merchant)</li>
                <li>Amount</li>
                <li>Date and Time of Transaction</li>
                <li>TayoCash Transaction Reference Number</li>
                <li>UnionBank Transaction Reference number (for Cash In thru UnionBank channels)</li>
              </ul>
            </li>
          </ul>
        </li>
        <li>Send us an email at <a class="link" href="mailto:ccg@tayocash.com">ccg@tayocash.com</a>:
          <ul class="loweralpha">
            <li>Email Subject: Cash In Transaction Issue</li>
            <li>Email Content:
              <ul>
                <li>Cash In Channel  (e.g. UnionBank, TayoCash Partner/Merchant)</li>
                <li>Amount</li>
                <li>Date and Time of Transaction</li>
                <li>TayoCash Transaction Reference Number</li>
                <li>UnionBank Transaction Reference Number (for Cash In thru UnionBank channels)</li>
                <li>
                  <a class="link" href="https://assets.prod.tayocash.com/forms/transaction_dispute_form.pdf" download target="_blank">Transaction Dispute Form</a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ol>
      <p>A TayoCash Customer Care Representative will get back to you within 48 hours after you have submitted the ticket.</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'cashin'
}
</script>

<style scoped lang='scss'>
</style>
